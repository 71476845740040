import { graphql } from "gatsby";
import { useTranslation } from "react-i18next/dist/es/useTranslation";

import Layout from "../../layouts";
import Heading from "../../components/Heading";
import Container from "../../components/Container";
import EmailForm from "../../components/Forms/_email";
import supportCom from "../../components/ForMarkDownComponents/_support";
import {
  unsubscribeNoticeText,
  unsubscribeFooterTextNotConnectServer,
  unsubscribeIsNotFindEmailText,
} from "../../components/Notice/constantMessage";

import { createClassName } from "../../helpers";
import "./style.scss";

const cn = createClassName("unsubscribe");

const Unsubscribe = (data, props) => {
  const { t } = useTranslation("forms");
  const { htmlAst, frontmatter } = data.data.markdownRemark;
  return (
    <Layout {...frontmatter.seo[0]} noForRobots>
      <Container className={cn({ wrap: true })}>
        <Heading level={1} fontLevel={3} className={cn("heading")}>
          {frontmatter.heading}
        </Heading>
        <EmailForm
          withPrivacy={false}
          withEmailAddress={false}
          buttonTextKey="unsubscribe"
          successHeaderTitle={t("unsubscribe.successHeaderTitle")}
          errorHeaderTitle={t("unsubscribe.errorHeaderTitle")}
          classNameSubmit={cn("form", { btn: true })}
          errorFooterTextNotConnectServer={
            unsubscribeFooterTextNotConnectServer
          }
          successFooterText={unsubscribeNoticeText(props)}
          errorFooterText={unsubscribeIsNotFindEmailText}
          fieldClassName={cn("form", { notice: true })}
          classNameForm={cn("form", { wrap: true })}
          content={supportCom(htmlAst)}
          apiLink="unsubscribe"
          tryAgain
        />
      </Container>
    </Layout>
  );
};

export default Unsubscribe;

export const query = graphql`
  query getUnsubscribe($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        seo {
          description
          link
          title
        }
        bannerTitle
        heading
      }
    }
  }
`;
